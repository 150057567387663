<template>
  <div class="app-fixed-search d-flex justify-content-between">
    <!-- Toggler -->
    <div class="sidebar-toggle d-block d-lg-none ml-1 my-auto">
      <feather-icon icon="MenuIcon" size="21" class="cursor-pointer" @click="updateMqShallShowLeftSidebar(true)" />
    </div>
    <div class="d-flex align-content-center justify-content-between w-100 align-middle pl-1 p-50">
      <strong class="mt-75">{{ title }}</strong>
      <div>
        <b-link class="mt-2 pr-1 h4" v-if="Object.keys(getUrun).length > 0" href="#" target="_blank">
          {{ getUrun.icerik['tr'].urun_adi }}
        </b-link>
        <b-button
          squared
          variant="success"
          v-if="Object.keys(getUrun).length > 0"
          :to="{ name: 'urun-ekle' }"
          v-b-tooltip.hover.top="'Yeni Ürün Ekle'"
        >
          <feather-icon icon="PlusIcon" size="18" />
        </b-button>
        <b-button
          squared
          variant="danger"
          v-if="Object.keys(getUrun).length > 0"
          @click="$emit('removeProduct', getUrun.k_no)"
          v-b-tooltip.hover.top="'Ürünü Sil'"
        >
          <feather-icon icon="Trash2Icon" size="18" class="cursor-pointer" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['getUrun']),
  },
  methods: {
    updateMqShallShowLeftSidebar(statu) {
      this.$emit('mqShallShowLeftSidebar', statu);
    },
    handlerTest(event) {
      this.$emit('removeProduct', event);
    },
  },
};
</script>

<style lang="scss" scoped></style>
